.existingReportSelect{
    padding-top: 10px;
}

.exportButton{
    float: right;
    padding-right: 10px;
    padding-top: 10px;
}

.exportTable{
    border: solid grey 1px;
    overflow: scroll;
    min-height: 300px;
    background-color: white;
    align-content: center;
    margin-left: 10px;
    margin-right: 10px;
    max-height: 400px;
}

.reportWrapper{
    width: 95%;
    background-color: #f2f2f2;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 10px;
    margin: auto;
    max-width: 800px;
}