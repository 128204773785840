.dashCard{
    font-family: 'Bebas Neue', cursive;
    text-align: center;
}

.cardTitle{
    font-size: 50px;
    /* margin-right: 10%; */
}

.dashLabel{
    color: #0F508A;
}

.dashValue{
    font-size: larger;
}