.loginError{
    text-align: center;
    font-size: 18px;
    color: red;
    padding-top: 1em;
}

.resetLink{
    text-align: right;
    font-size: 12px;
}

form{
    padding-top: 10px;
}

.loginCard{
    margin-top: 3em;
    /* background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); */
}

.submitButton{
    margin-top: 1em;
}