.dashCard{
    font-family: 'Bebas Neue', cursive;
    text-align: center;
    margin-top: 2%;
}

.cardTitle{
    font-size: 50px;
    /* margin-right: 10%; */
}

.seasonSpacer{
    height: 30px
}