.inlineSpacer{
    margin-right: 30px;
}

.wxIcon{
    display: inline-block;
    vertical-align: middle;
}

p{
    display: inline-block;
    font-size: 17px;
    margin-right: 10px;
}
